import { MutationHookOptions, useMutation } from '@apollo/client'
import gql from 'graphql-tag'
import { Mutation, MutationQualifyLeadArgs } from '@/schemaTypes'

const QUALIFY_CUSTOM_DEAL = gql`
  mutation qualifyCustomDeal($args: QualifyLeadArgs!) {
    qualifyLead(args: $args) {
      _id
      qualified {
        at
        value
        by {
          _id
          firstname
          lastname
        }
      }
    }
  }
`

type TData = { qualifyLead: Mutation['qualifyLead'] }
type TVariables = MutationQualifyLeadArgs

export function useQualifyCustomDeal(
  opts: MutationHookOptions<TData, TVariables> = {},
) {
  const [qualifyLead] = useMutation<TData, TVariables>(
    QUALIFY_CUSTOM_DEAL,
    opts,
  )
  return qualifyLead
}
