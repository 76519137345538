import * as Yup from 'yup'
import { ECustomDealStatusType } from '@/schemaTypes'

export type CustomDealSchemaType = Yup.InferType<typeof customDealSchema>

export const customDealSchema = Yup.object().shape({
  durationInDays: Yup.number()
    .integer('error.error_validation_is_number')
    .required('error.error_validation_require_duration'),
  dealType: Yup.string().required(),
  customerId: Yup.string().nullable(),
  employeeId: Yup.string().nullable(),
  note: Yup.string(),
  contactData: Yup.object().when('customerId', {
    is: (value) => !value,
    then: (schema) =>
      schema.shape(
        {
          email: Yup.string().when('phone', {
            is: (value) => !value,
            then: (schema) =>
              schema
                .email('error.error_validation_is_email')
                .required('error.error_validation_require_email_or_telephone'),
          }),
          phone: Yup.string().when('email', {
            is: (value) => !value,
            then: (schema) =>
              schema.required(
                'error.error_validation_require_email_or_telephone',
              ),
          }),
        },
        [['email', 'phone']],
      ),
  }),
  shopId: Yup.string().when('status', {
    is: (value) => value === ECustomDealStatusType.Reviewing,
    then: (schema) => schema.required(),
  }),
  items: Yup.array().min(1, 'error.error_validation_require_one_item'),
  employeeResponse: Yup.object()
    .nullable()
    .when('status', {
      is: ECustomDealStatusType.Declined,
      then: (schema) =>
        schema
          .shape({
            editableText: Yup.string().test(
              'editableText',
              'error.error_validation_is_required',
              (value) => !!value?.trim(),
            ),
            name: Yup.string().required(),
            subject: Yup.string().required(),
            openingText: Yup.string().required(),
            closingText: Yup.string().required(),
          })
          .nullable()
          .required('error.error_validation_is_required'),
    }),
})
