import ExtensionBox from '../ExtensionBox/ExtensionBox'
import PaybackBox from '../PaybackBox/PaybackBox'
import VerifyBox from '../VerifyBox/VerifyBox'
import { Checkbox } from '@mui/material'
import dayjs from 'dayjs'
import { Tooltip } from 'primereact/tooltip'
import React, { useCallback, useContext, useState } from 'react'
import { useTranslation } from 'react-i18next'
// Redesign this
import ShopSelect from '@/components/ShopSelect'
import { Context } from '@/context'
import { useOnDealStatusChange } from '@/domains/deals/hooks'
import { useAddKSVAssetMutation } from '@/domains/deals/hooks/addKSVAsset'
import { useChangeKSVAssetMutation } from '@/domains/deals/hooks/changeKsvAsset'
import { useCreateReceiptWithoutIdentity } from '@/domains/deals/hooks/createReceiptWithoutIdentity'
import {
  useOnDealStatusClosed,
  useOnDealStatusExtensionConfirmed,
  useOnDealStatusPaybackConfirmed,
  useOnDealStatusVerified,
} from '@/domains/deals/hooks/dealStatusChanges'
import { useUpdateDealAuctioningSettings } from '@/domains/deals/hooks/updateDealAuctioningSettings'
import { useUpdateDealOnlineExtensionPrevention } from '@/domains/deals/hooks/updateDealOnlineExtensionPrevention'
import { useMutationShowingErrors, useShowConfirmPopup } from '@/hooks'
import {
  EBusinessUnit,
  ECountry,
  EDealType,
  EManualPaymentType,
  EStorageStatus,
  EksvRequestType,
} from '@/schemaTypes'
import { DealEventType } from '@/types'
import { dealEventDTOs, getDealValueEntry } from '@/utils/deal'
import { isShopLevelPaymentType, paymentTypeUnion } from '@/utils/misc'
import GeneralInfo, { GeneralInfoProps } from './GeneralInfo'
import styles from './GeneralInfo.module.scss'

export interface INoteData {
  note: string
  isNotePopup: boolean
}

type GeneralInfoContainerProps = Pick<
  GeneralInfoProps,
  'deal' | 'queryResult' | 'latestDealValues' | 'activeEvent' | 'warnBookedDate'
>

const AUTO_SET_STATUS_LIST: DealEventType[] = [
  'DealLoanDueNotifiedEvent',
  'DealLoanDueEvent',
  'DealOnSellEvent',
  'DealBookedEvent',
]
const defaultRemoveItemsFromStorage = true
const extensionBanNotePrefix = 'Verlängerung blockiert weil:'

export default function GeneralInfoContainer(props: GeneralInfoContainerProps) {
  const { deal, queryResult } = props
  const { showInfo, closeConfirmModal, showErrors } = useContext(Context)
  const { t } = useTranslation()
  const [dealStatusLoading, setDealStatusLoading] = useState(false)
  const [receiptGeneratingIsRunning, setReceiptGeneratingIsRunning] =
    useState(false)
  const [dealVerificationError, setDealVerificationError] = useState(false)
  const today = dayjs().toDate()
  const isAustriaCarPawn =
    deal.businessUnit === EBusinessUnit.Car &&
    deal.dealType === EDealType.Pawn &&
    deal.company.headquarter?.countryCode === ECountry.At

  // If car pawn, there should be only one item in the deal?
  const findAssetQueryAt = deal.items[0]?.vehicleData?.ksvQueryHistories?.find(
    (history) =>
      history.type === EksvRequestType.FindAssets &&
      history.vin === deal.items[0]?.vehicleData?.vin,
  )?.queryAt
  const showOverruleConfirm =
    isAustriaCarPawn &&
    (!findAssetQueryAt ||
      dayjs(findAssetQueryAt).isBefore(dayjs().subtract(24, 'hours')))

  const updateOnlineExtensionPrevented = useMutationShowingErrors({
    mutation: useUpdateDealOnlineExtensionPrevention(),
    successMessage: `${t('update_successful')}!`,
  })

  const onUpdateIsOnlineExtensionPrevented = (
    isOnlineExtensionPrevented: boolean,
    note?: string,
  ) => {
    updateOnlineExtensionPrevented({
      variables: {
        dealId: deal._id,
        isOnlineExtensionPrevented,
        note: `${extensionBanNotePrefix} ${note}`,
      },
    })
  }

  const showConfirmPopup = useShowConfirmPopup({
    actionText: t('change_status'),
  })
  const changeDealStatus = useOnDealStatusChange(deal)

  const addKSVAsset = useAddKSVAssetMutation({
    onError: () =>
      showErrors([
        {
          title: t('error.error_title_api_error'),
          description: t('error.could_not_create_ksv_asset'),
        },
      ]),
  })

  const changeKSVAsset = useChangeKSVAssetMutation({
    onError: () =>
      showErrors([
        {
          title: t('error.error_title_api_error'),
          description: t('error.could_not_change_ksv_asset'),
        },
      ]),
  })

  const updateDealAuctioningSettings = useUpdateDealAuctioningSettings()

  const confirmPayback = useOnDealStatusPaybackConfirmed({
    dealPaybackCalculationId: '',
    dealId: deal._id,
    paymentType: EManualPaymentType.Cash,
    removeItemsFromStorage: defaultRemoveItemsFromStorage,
  })

  const confirmExtension = useOnDealStatusExtensionConfirmed({
    dealExtensionCalculationId: '',
    dealId: deal._id,
    paymentType: EManualPaymentType.Cash,
    isChildOverwrittenFees: false,
    isOnlineExtensionPrevented: Boolean(
      deal.dealFinalValues.isOnlineExtensionPrevented,
    ),
  })

  const confirmClosed = useOnDealStatusClosed(
    deal._id,
    defaultRemoveItemsFromStorage,
  )

  const confirmVerified = useOnDealStatusVerified({
    dealId: deal._id,
    isOnlineExtensionPrevented: false,
    shouldOverwriteManualFees: false,
    manualFeeDefinitionsArgs: [],
  })

  const checkValidStatusPayload = (status: DealEventType, state) => {
    let isValid = true
    if (status === 'DealVerifiedEvent' && !state.verifiedShopId) {
      showInfo(t('deal.must_select_cashbook_for_verified_deal'))
      isValid = false
    } else if (
      (status === 'DealVerifiedEvent' ||
        status === 'DealExtensionConfirmedEvent') &&
      state.isOnlineExtensionPrevented &&
      !state.onlineExtensionBanNote
    ) {
      showInfo(t('deal.must_set_reason_for_online_extension_ban'))
      isValid = false
    } else if (
      status === 'DealExtensionConfirmedEvent' ||
      status === 'DealPaybackConfirmedEvent'
    ) {
      if (!state.paymentType) {
        showInfo(t('deal.payment_type_is_not_selected'))
        isValid = false
      } else if (state.withdrawalFeeTermsAccepted === false) {
        showInfo(t('deal.must_check_withdrawal_fee_terms'))
        isValid = false
      } else if (
        isShopLevelPaymentType(state.paymentType) &&
        !state.extendedShopId &&
        !state.paybackConfirmedShopId
      ) {
        showInfo(
          t(
            status === 'DealExtensionConfirmedEvent'
              ? 'deal.must_select_cashbook_for_extended_deal'
              : 'deal.must_select_cashbook_for_payback_confirm_deal',
          ),
        )
        isValid = false
      }
    }
    return isValid
  }

  const onDealStatusChange = useCallback(
    async (status: DealEventType) => {
      let component: ((state: any, setState: any) => JSX.Element) | undefined =
        undefined

      if (AUTO_SET_STATUS_LIST.includes(status)) {
        return showInfo(
          `${t('status_automatically_set_warn', { status: status })}!`,
        )
      }

      let popupTitle = t('deal.confirm_status_change_question', {
        status: dealEventDTOs[status],
      })

      if (
        status === 'DealPayedAndStoredEvent' &&
        deal?.items?.some(
          (item) =>
            !item.storageHistory ||
            item.storageHistory[0].storageStatus ===
              EStorageStatus.RemovedFromStorage,
        )
      ) {
        return showInfo(t('deal.some_deal_items_do_not_have_storage_label'))
      }

      if (
        status === 'DealPaybackConfirmedEvent' ||
        status === 'DealClosedEvent'
      ) {
        component = (state, setState) => {
          return (
            <>
              {status === 'DealPaybackConfirmedEvent' && (
                <PaybackBox
                  deal={deal}
                  newPaymentType={state.paymentType}
                  onChangeDealPaybackCalculationId={(
                    dealPaybackCalculationId,
                  ) =>
                    setState((state) => ({
                      ...state,
                      dealPaybackCalculationId,
                    }))
                  }
                  onChangePaymentType={(paymentType) =>
                    setState((state) => ({ ...state, paymentType }))
                  }
                  withdrawalFeeTermsAccepted={state.withdrawalFeeTermsAccepted}
                  setWithdrawalFeeTermsAccepted={(withdrawalFeeTermsAccepted) =>
                    setState({
                      ...state,
                      withdrawalFeeTermsAccepted,
                    })
                  }
                />
              )}
              <Checkbox
                color="error"
                defaultChecked={defaultRemoveItemsFromStorage}
                onChange={(e) => {
                  setState({
                    ...state,
                    removeItemsFromStorage: e.target.checked,
                  })
                }}
              />
              {t('deal.remove_item_from_storage_auctomatically')}

              {status === 'DealPaybackConfirmedEvent' &&
                isShopLevelPaymentType(
                  state.paymentType ?? EManualPaymentType.Cash,
                ) && (
                  <div className={styles.flexCenterWrapper}>
                    <p style={{ marginBottom: '1rem' }}>
                      {t('select_cashbook_for_booking')}:
                    </p>
                    <ShopSelect
                      companyId={deal.company?._id}
                      shopId={state.paybackConfirmedShopId}
                      onChange={(value) => {
                        setState({ ...state, paybackConfirmedShopId: value })
                      }}
                      useDefaultShopId
                      includeDisabled
                    />
                  </div>
                )}
            </>
          )
        }
      } else if (status === 'DealExtensionConfirmedEvent') {
        component = (state, setState) => {
          const companyId =
            deal.company.successionCompany?._id || deal.company._id

          return (
            <>
              <ExtensionBox
                deal={deal}
                newPaymentType={state.paymentType}
                onChangeDealExtensionCalculationId={(
                  dealExtensionCalculationId,
                ) => setState({ ...state, dealExtensionCalculationId })}
                onChangePaymentType={(paymentType) =>
                  setState({ ...state, paymentType })
                }
                onChangeChildOverwriteManualFees={(
                  childShouldOverwriteManualFees,
                ) => setState({ ...state, childShouldOverwriteManualFees })}
                isOnlineExtensionPrevented={state.isOnlineExtensionPrevented}
                onChangeIsOnlineExtensionPrevented={(
                  isOnlineExtensionPrevented,
                ) => setState({ ...state, isOnlineExtensionPrevented })}
                onlineExtensionBanNote={state.onlineExtensionBanNote}
                onChangeOnlineExtensionBanNote={(onlineExtensionBanNote) =>
                  setState({ ...state, onlineExtensionBanNote })
                }
                withdrawalFeeTermsAccepted={state.withdrawalFeeTermsAccepted}
                setWithdrawalFeeTermsAccepted={(withdrawalFeeTermsAccepted) =>
                  setState({
                    ...state,
                    withdrawalFeeTermsAccepted,
                  })
                }
              />

              {isShopLevelPaymentType(
                state.paymentType ?? EManualPaymentType.Cash,
              ) && (
                <div className={styles.flexCenterWrapper}>
                  <p style={{ marginBottom: '1rem' }}>
                    {t('select_cashbook_for_booking')}:
                  </p>
                  <ShopSelect
                    companyId={companyId}
                    shopId={state.extendedShopId}
                    onChange={(value) => {
                      setState({ ...state, extendedShopId: value })
                    }}
                    useDefaultShopId
                  />
                </div>
              )}
            </>
          )
        }
      } else if (
        status === 'DealSoldInternEvent' &&
        getDealValueEntry(deal, 'DealClosedEvent').paybackAmount !==
          getDealValueEntry(deal, 'DealClosedEvent').calculationPaybackAmount
      ) {
        popupTitle = `${t('deal_will_reset_overwritten_payback_amount')} ${popupTitle}`
      } else if (status === 'DealVerifiedEvent') {
        component = (state, setState) => {
          return (
            <>
              <VerifyBox deal={deal} state={state} setState={setState} />
            </>
          )
        }
      }

      showConfirmPopup({
        title: popupTitle,
        state: {
          paybackType: paymentTypeUnion(deal.payoutData.paymentType),
          date: new Date(),
          overwrittenFeeAmount: undefined,
          overwrittenPartialRedemptionAmount: undefined,
          durationInDays: undefined,
          canSubmitPayBackConfirmed: '',
          canSubmitExtensionConfirmed: '',
          disableRightButton:
            status === 'DealVerifiedEvent' && showOverruleConfirm,
          verifiedShopId: undefined,
          extendedShopId: undefined,
          paybackConfirmedShopId: undefined,
          shouldOverwriteManualFees: false,
          manualFeeDefinitionsArgs: [],
          isOnlineExtensionPrevented: Boolean(
            deal.dealFinalValues.isOnlineExtensionPrevented,
          ),
          showOverruleConfirm: isAustriaCarPawn && !findAssetQueryAt,
          finalMaxPawnDate: dayjs(today).add(181, 'days'),
          withdrawalFeeTermsAccepted: deal.acceptingWithdrawalFeeTermsRequired
            ? false
            : undefined,
        },
        component,
        action: async (state) => {
          try {
            setDealStatusLoading(true)
            if (!checkValidStatusPayload(status, state)) return

            closeConfirmModal()

            if (
              state &&
              state.paybackType &&
              state.paybackType !== deal.pawnData?.paybackData?.paymentType
            ) {
              if (
                state.canSubmitPayBackConfirmed &&
                state.canSubmitPayBackConfirmed.length > 0
              ) {
                showInfo(state.canSubmitPayBackConfirmed)
              } else if (
                state.canSubmitExtensionConfirmed &&
                state.canSubmitExtensionConfirmed.length > 0
              ) {
                showInfo(state.canSubmitExtensionConfirmed)
              }
            }

            const changeDealStatusResult = await (() => {
              switch (status) {
                case 'DealPaybackConfirmedEvent':
                  return confirmPayback({
                    variables: {
                      args: {
                        dealPaybackCalculationId:
                          state.dealPaybackCalculationId,
                        dealId: deal._id,
                        paymentType:
                          state.paymentType ?? EManualPaymentType.Cash,
                        shopId: state.paybackConfirmedShopId,
                        removeItemsFromStorage:
                          state.removeItemsFromStorage ??
                          defaultRemoveItemsFromStorage,
                      },
                    },
                  })

                case 'DealExtensionConfirmedEvent':
                  return confirmExtension({
                    variables: {
                      args: {
                        dealExtensionCalculationId:
                          state.dealExtensionCalculationId,
                        dealId: deal._id,
                        paymentType:
                          state.paymentType ?? EManualPaymentType.Cash,
                        shopId: state.extendedShopId,
                        isChildOverwrittenFees: Boolean(
                          state.childShouldOverwriteManualFees,
                        ),
                        isOnlineExtensionPrevented: Boolean(
                          state.isOnlineExtensionPrevented,
                        ),
                      },
                    },
                  })

                case 'DealClosedEvent':
                  return confirmClosed({
                    variables: {
                      dealId: deal._id,
                      removeItemsFromStorage:
                        state.removeItemsFromStorage ??
                        defaultRemoveItemsFromStorage,
                    },
                  })
                case 'DealVerifiedEvent':
                  return confirmVerified({
                    variables: {
                      verifyDealArgs: {
                        dealId: deal._id,
                        shopId: state.verifiedShopId,
                        shouldOverwriteManualFees:
                          state.shouldOverwriteManualFees,
                        manualFeeDefinitionsArgs:
                          state.manualFeeDefinitionsArgs,
                        isOnlineExtensionPrevented:
                          state.isOnlineExtensionPrevented,
                        onlineExtensionBanNote: state.onlineExtensionBanNote,
                        finalMaxPawnDate: state.finalMaxPawnDate,
                      },
                    },
                  })

                default:
                  return changeDealStatus(status)
              }
            })()

            if (changeDealStatusResult?.error) {
              return setDealVerificationError(true)
            } else {
              queryResult.refetch()
            }

            // Calling APIs to update KSV asset with Austria car pawn
            if (isAustriaCarPawn) {
              switch (status) {
                case 'DealVerifiedEvent': {
                  addKSVAsset({
                    variables: {
                      itemId: deal.items[0]._id,
                    },
                  })
                  break
                }
                case 'DealExtensionConfirmedEvent': {
                  changeKSVAsset({
                    variables: {
                      itemId: deal.items[0]._id,
                    },
                  })
                  break
                }
              }
            }
          } finally {
            setDealStatusLoading(false)
          }
        },
      })
    },
    // TODO: CQI-2 fix this violation of react-hooks/exhaustive-deps
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [deal],
  )

  const createReceiptWithoutIdentity = useMutationShowingErrors({
    mutation: useCreateReceiptWithoutIdentity(),
  })

  const onCreateReceiptWithoutIdentity = () => {
    setReceiptGeneratingIsRunning(true)
    createReceiptWithoutIdentity({
      variables: { dealId: deal._id },
    }).then(() => {
      setReceiptGeneratingIsRunning(false)
    })
  }

  return (
    <>
      <Tooltip target=".custom-target-icon" />
      <GeneralInfo
        {...props}
        onDealStatusChange={onDealStatusChange}
        dealStatusLoading={dealStatusLoading}
        dealVerificationError={dealVerificationError}
        onCreateReceiptWithoutIdentity={onCreateReceiptWithoutIdentity}
        receiptGeneratingIsRunning={receiptGeneratingIsRunning}
        onUpdateIsOnlineExtensionPrevented={onUpdateIsOnlineExtensionPrevented}
        onChangeAuctioningSettings={(auctioningAllowed) =>
          updateDealAuctioningSettings({
            variables: { args: { dealId: deal._id, auctioningAllowed } },
          })
        }
      />
    </>
  )
}
