import { MutationHookOptions, useMutation } from '@apollo/client'
import gql from 'graphql-tag'
import { useTranslation } from 'react-i18next'
import { useMutationShowingErrors } from '@/hooks'
import {
  Mutation,
  MutationUpdateDealAuctioningSettingsArgs,
} from '@/schemaTypes'

const mutation = gql`
  mutation updateDealAuctioningSettings(
    $args: UpdateDealAuctioningSettingsArgs!
  ) {
    updateDealAuctioningSettings(args: $args) {
      _id
      auctioningSettings {
        auctioningAllowed
        at
        by {
          firstname
          lastname
        }
      }
    }
  }
`

type TData = {
  updateDealAuctioningSettings: Mutation['updateDealAuctioningSettings']
}
type TVariables = MutationUpdateDealAuctioningSettingsArgs

export function useUpdateDealAuctioningSettings(
  opts: MutationHookOptions<TData, TVariables> = {},
) {
  const [updateDealAuctioningSettings] = useMutation<TData, TVariables>(
    mutation,
    {
      ...opts,
    },
  )

  const { t } = useTranslation()
  return useMutationShowingErrors({
    mutation: updateDealAuctioningSettings,
    successMessage: t('update_successful'),
  })
}
