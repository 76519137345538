import styled from '@emotion/styled'
import dayjs from 'dayjs'
import { Button } from 'primereact/button'
import { Link } from 'react-router-dom'
import { Customer } from '@/schemaTypes'

export interface CustomerInfoCardProps {
  customer: Customer
  isMainCustomer?: boolean
  onRemoveSecondaryCustomerFromDeal?: (customerId: string) => void
  canEditCustomer: boolean
  isGuestUser: boolean
  isDealVerified: boolean
  onChangeCustomer?: () => void
}

const CustomerInfoCard = (props: CustomerInfoCardProps) => {
  const {
    customer,
    isMainCustomer = false,
    onRemoveSecondaryCustomerFromDeal,
    canEditCustomer,
    isGuestUser,
    isDealVerified,
    onChangeCustomer,
  } = props

  return (
    <>
      <AddressOverviewContainer className="flex flex-col">
        <div className="flex flex-row justify-end">
          {/* Change customer */}
          {!isDealVerified && isMainCustomer && (
            <Button
              icon="pi pi-arrows-h"
              text
              severity="secondary"
              onClick={onChangeCustomer}
            />
          )}
          {/* Edit customer */}
          {canEditCustomer && !isGuestUser && (
            <Link
              id="DEAL_DETAIL_EDIT_CUSTOMER_LINK"
              to={`/inApp/customers/edit/${customer._id}`}
              target="_blank"
              rel="noreferrer"
            >
              <Button icon="pi pi-user-edit" text severity="secondary" />
            </Link>
          )}
          {/* Remove customer */}
          {!isMainCustomer && (
            <Button
              icon="pi pi-trash"
              text
              severity="danger"
              onClick={() => onRemoveSecondaryCustomerFromDeal(customer._id)}
            />
          )}
        </div>
        <div className="flex flex-col px-3.5 pb-3.5 mx-2">
          <CustomerElement>
            <IconStyled className="pi pi-id-card" />
            <TextStyled>
              {customer.firstname + ' ' + customer.lastname}
            </TextStyled>
          </CustomerElement>
          <CustomerElement>
            <IconStyled className="pi pi-calendar" />
            <TextStyled>
              {dayjs(customer.dateOfBirth).format('DD.MM.YYYY')}
            </TextStyled>
          </CustomerElement>
          <CustomerElement>
            <IconStyled className="pi pi-send" />
            <TextStyled>{customer.email}</TextStyled>
          </CustomerElement>
          <CustomerElement>
            <IconStyled className="pi pi-phone" />
            <TextStyled>{customer.phone}</TextStyled>
          </CustomerElement>
          <CustomerElement>
            <IconStyled className="pi pi-exclamation-circle" />
            <TextStyled className="text-ellipsis line-clamp-6">
              {customer.note || '-'}
            </TextStyled>
          </CustomerElement>
        </div>
      </AddressOverviewContainer>
    </>
  )
}
const AddressOverviewContainer = styled.div`
  width: 20rem;
  flex-shrink: 0;
  border-top-left-radius: 0.375rem;
  border-top-right-radius: 0.375rem;
  background: var(--surface-50, #fafafa);
  @media (max-width: 768px) {
    width: 100%;
  }
`

const CustomerElement = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 0.4375rem;
  margin-bottom: 0.5rem;
`

const TextStyled = styled.p`
  color: var(--global-textColor, #495057);
  font-family: Inter;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 600;
  line-height: 0.875rem;
`

const IconStyled = styled.i`
  font-size: 1.1rem;
  color: #9e9e9e;
`

export default CustomerInfoCard
