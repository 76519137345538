import { ImageGallery } from '..'
import { CustomDealItem } from '../../../helpers'
import { IMedia } from '../ImageGallery'
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@material-ui/core'
import DeleteForeverIcon from '@material-ui/icons/DeleteForever'
import EditIcon from '@material-ui/icons/Edit'
import VisibilityIcon from '@material-ui/icons/Visibility'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { EDealType } from '@/schemaTypes'
import {
  isVehicle,
  positiveNumberOrUndefined,
  printLocalFloat,
} from '@/utils/misc'
import styles from './styles.module.scss'

interface Props {
  items: CustomDealItem[]
  dealType: EDealType
  onRemoveItem: (index: number) => void
  onEditItem: (index: number) => void
  onViewItem: (index: number) => void
}

export function CustomDealItemsList(props: Props) {
  const { items, dealType, onRemoveItem, onEditItem, onViewItem } = props
  const { t } = useTranslation()
  const [isOpenImageGallery, setOpenImageGallery] = useState(false)
  const [mediaGallery, setMediaGallery] = useState<IMedia[]>([])
  const [viewedPositionIndex, setViewedPositionIndex] = useState(0)

  const onCloseImageGallery = () => {
    setOpenImageGallery(false)
    setViewedPositionIndex(0)
    setMediaGallery([])
  }

  const onOpenImageGallery = (media: { url: string }[], index: number) => {
    setViewedPositionIndex(index)
    setMediaGallery(
      media.map((media) => ({
        url: media.url,
      })),
    )
    setOpenImageGallery(true)
  }

  return (
    <Table className="u-mb-sm">
      <TableHead>
        <TableRow>
          <TableCell>{t('product_reference')}</TableCell>
          <TableCell>{t('item_description')}</TableCell>
          <TableCell>{t('customer_requested_price')}</TableCell>
          <TableCell>{t('payout_pawn')}</TableCell>
          <TableCell>{t('payout_purchase')}</TableCell>
          <TableCell>{t('item_actions')}</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {items?.map((item, index) => (
          <React.Fragment key={index}>
            <TableRow>
              <TableCell scope="row">
                {item?.algoliaReference ??
                  item?.variantId ??
                  (item?.vehicleData?.indicataId ? t('vehicle') : t('custom'))}
              </TableCell>
              <TableCell scope="row">{item.title}</TableCell>
              <TableCell>
                {printLocalFloat(
                  dealType === EDealType.Pawn
                    ? item?.pawnPayoutAmount
                    : item?.purchasePayoutAmount,
                )}
              </TableCell>

              <TableCell>
                {printLocalFloat(
                  positiveNumberOrUndefined(
                    item?.itemPawnValueEntry?.payoutAmount,
                  ),
                )}
              </TableCell>
              <TableCell>
                {isVehicle(item?.itemCategoryId)
                  ? '-'
                  : printLocalFloat(
                      positiveNumberOrUndefined(
                        item?.itemPurchaseValueEntry?.payoutAmount,
                      ),
                    )}
              </TableCell>
              <TableCell>
                <button
                  onClick={() => onViewItem(index)}
                  style={{
                    backgroundColor: 'transparent',
                    border: 'none',
                    cursor: 'pointer',
                    color: '#673ab7',
                  }}
                >
                  <VisibilityIcon />
                </button>
                <button
                  onClick={() => onEditItem(index)}
                  style={{
                    backgroundColor: 'transparent',
                    border: 'none',
                    cursor: 'pointer',
                    color: '#673ab7',
                  }}
                >
                  <EditIcon />
                </button>
                <button
                  onClick={() => onRemoveItem(index)}
                  style={{
                    backgroundColor: 'transparent',
                    border: 'none',
                    cursor: 'pointer',
                    color: '#673ab7',
                  }}
                >
                  <DeleteForeverIcon />
                </button>
              </TableCell>
            </TableRow>
            {!!item.media?.length && (
              <TableRow>
                <TableCell colSpan={4} scope="row">
                  <div className={styles.mediaList}>
                    {item.media?.map((media, index) => (
                      <img
                        onClick={() =>
                          onOpenImageGallery(item.media ?? [], index)
                        }
                        className={styles.media}
                        src={media.url}
                        alt="item-media"
                      />
                    ))}
                  </div>
                </TableCell>
              </TableRow>
            )}
          </React.Fragment>
        ))}
      </TableBody>

      {isOpenImageGallery && (
        <ImageGallery
          media={mediaGallery}
          viewedPositionIndex={viewedPositionIndex}
          onClose={onCloseImageGallery}
        />
      )}
    </Table>
  )
}
