import { CustomItemDraftFormData } from '../CustomItemDraft/CustomItemDraft'
import styled from '@emotion/styled'
import dayjs from 'dayjs'
import { FieldArray, FormikErrors, useFormik } from 'formik'
import { Button } from 'primereact/button'
import { Message } from 'primereact/message'
import { SelectItem } from 'primereact/selectitem'
import { useEffect, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { onlyOverwriteIsPossible } from '@/domains/customDeals/components/CustomDealDetail/CustomDealItems'
import { getMaxComparableOffersCount } from '@/domains/customDeals/helpers'
import FormikInputNum from '@/redesign/components/FormikFields/FormikInputNum/FormikInputNum'
import Input from '@/redesign/components/Input/Input'
import InputDropdown from '@/redesign/components/InputDropdown/InputDropdown'
import InputNum from '@/redesign/components/InputNumber/InputNumber'
import Switch from '@/redesign/components/Switch/Switch'
import {
  ECustomItemFoundComparableOffers,
  EDealType,
  ItemValuesEntry,
  PriceFinding,
  PriceFindingArgs,
  Scalars,
} from '@/schemaTypes'

interface PriceDeterminationProps {
  isVehicle: boolean
  itemPawnValuesEntry?: ItemValuesEntry
  itemPurchaseValuesEntry?: ItemValuesEntry
  dealType: EDealType
  foundComparableOffers: ECustomItemFoundComparableOffers | undefined
  values: (PriceFindingArgs | PriceFinding)[]
  readonly?: boolean
  formikErrors?: FormikErrors<CustomItemDraftFormData>
  setFieldValue: ReturnType<
    typeof useFormik<CustomItemDraftFormData>
  >['setFieldValue']
  setValues: ReturnType<typeof useFormik<CustomItemDraftFormData>>['setValues']
  calculationRunning: boolean
  dealOverwriteItemPayouts?: boolean
  overwriteItemPayouts?: boolean
  overWriteItemPayoutsReadonly?: boolean
  setOverwriteItemPayouts: (value: boolean) => void
  priceFindingLengthError?: string
  overwrittenItemPayoutsError?: string
  counterofferPayoutAmount?: number | null
  variantId?: Scalars['ObjectId']
  overwrittenPawnPayoutAmount?: number | null
  overwrittenPurchasePayoutAmount?: number | null
}

const PriceDetermination = (props: PriceDeterminationProps) => {
  const {
    isVehicle,
    itemPawnValuesEntry,
    itemPurchaseValuesEntry,
    dealType,
    foundComparableOffers,
    values,
    setFieldValue,
    setValues,
    readonly,
    calculationRunning,
    dealOverwriteItemPayouts,
    overwriteItemPayouts,
    setOverwriteItemPayouts: _setOverwriteItemPayouts,
    priceFindingLengthError,
    overwrittenItemPayoutsError,
    counterofferPayoutAmount,
    variantId,
    overwrittenPawnPayoutAmount,
    overwrittenPurchasePayoutAmount,
    overWriteItemPayoutsReadonly,
  } = props

  const errors = props.formikErrors as FormikErrors<CustomItemDraftFormData>
  const priceFindingsErrors =
    errors?.priceFindings as FormikErrors<PriceFindingArgs>
  const { t } = useTranslation()

  const foundComparableOffersOptions = useMemo(
    () =>
      Object.values(ECustomItemFoundComparableOffers).map(
        (data): SelectItem => ({
          label: t(data.toLowerCase()),
          value: data,
          disabled:
            dealOverwriteItemPayouts === false && onlyOverwriteIsPossible(data),
        }),
      ),
    [t, dealOverwriteItemPayouts],
  )

  useEffect(() => {
    if (variantId)
      setFieldValue(
        'payoutAmount',
        dealType === EDealType.Pawn
          ? itemPawnValuesEntry?.payoutAmount
          : itemPurchaseValuesEntry?.payoutAmount,
      )
  }, [
    itemPawnValuesEntry,
    itemPurchaseValuesEntry,
    dealType,
    variantId,
    setFieldValue,
  ])

  const setOverwriteItemPayouts = (value: boolean) => {
    _setOverwriteItemPayouts(value)
    if (!value) {
      setValues((prevValues) => ({
        ...prevValues,
        overwrittenPawnPayoutAmount: undefined,
        overwrittenPurchasePayoutAmount: undefined,
      }))
    }
  }

  const ensureEmptyPriceFindingRecord = (
    insertRecord: (index: number, element: PriceFindingArgs) => void,
    editedRecordIndex: number,
    recordOverride?: PriceFindingArgs,
  ) => {
    if (
      values.length < getMaxComparableOffersCount(foundComparableOffers) &&
      editedRecordIndex === values.length - 1 &&
      !priceFindingsErrors &&
      Boolean(recordOverride?.price || values[editedRecordIndex].price) &&
      Boolean(recordOverride?.url || values[editedRecordIndex].url)
    ) {
      insertRecord(values.length, {
        url: null,
        price: null,
      })
    }
  }

  const onChangeFoundComparableOffers = (
    newValue: ECustomItemFoundComparableOffers,
  ) => {
    const maxOffers = getMaxComparableOffersCount(newValue)
    const newPriceFindings =
      values.length > maxOffers
        ? values.slice(0, maxOffers)
        : values.length < maxOffers
          ? [...values, { url: null, price: null }]
          : undefined
    setValues((prevValues) => ({
      ...prevValues,
      foundComparableOffers: newValue,
      priceFindings: newPriceFindings || prevValues.priceFindings,
    }))
  }

  return (
    <div>
      <TitleStyled>{t('cashy_determination_price')}</TitleStyled>

      {!variantId && (
        <div>
          <FormikInputNum
            name="payoutAmount"
            label={t('customer_requested_price')}
            className="flex flex-col md:flex-row items-start md:items-center mb-2"
            labelClassName="text-sm font-semibold w-12.5"
            inputContainerClassName="w-52"
          />
        </div>
      )}

      <Message
        className="!mb-2"
        text={t('cashy_determination_price_description')}
      />

      {dealOverwriteItemPayouts !== undefined && (
        <Message
          className="!mb-2"
          severity="warn"
          text={
            dealOverwriteItemPayouts
              ? t('deal_overwrite_mode_warning_only_manual_price_allowed')
              : t('deal_overwrite_mode_warning_only_automatic_price_allowed')
          }
        />
      )}

      {!counterofferPayoutAmount && (
        <div className="flex mb-2">
          <InputDropdown
            label={t('comparable_offers_found')}
            options={foundComparableOffersOptions}
            className="flex flex-col md:flex-row items-start md:items-center "
            labelClassName="text-sm font-semibold w-12.5"
            inputContainerClassName="w-52"
            value={foundComparableOffers}
            onChange={(e) =>
              onChangeFoundComparableOffers(
                e.target.value as ECustomItemFoundComparableOffers,
              )
            }
          />
          {priceFindingLengthError && (
            <Message
              className="!ml-4"
              severity="warn"
              text={t(priceFindingLengthError)}
            />
          )}
        </div>
      )}

      <FieldArray
        name="priceFindings"
        render={({ form, insert }) => {
          return (
            <>
              {values.map((priceFinding, i) => {
                return (
                  <div key={i} className="flex flex-col md:flex-row ">
                    <Input
                      label={t('url')}
                      onBlur={() => ensureEmptyPriceFindingRecord(insert, i)}
                      disabled={readonly}
                      className="flex flex-col md:flex-row items-start md:items-center mr-24 w-2/3 mb-2"
                      labelClassName="text-sm font-semibold w-24"
                      wrapperInputContainerClassName="w-full"
                      value={priceFinding.url}
                      onChange={(e) => {
                        const newValue = e.target.value
                        form.setFieldValue(`priceFindings.[${i}].url`, newValue)
                        ensureEmptyPriceFindingRecord(insert, i, {
                          url: newValue,
                        })
                      }}
                      required
                    />

                    <InputNum
                      label={t('price')}
                      value={priceFinding.price}
                      onBlur={() => ensureEmptyPriceFindingRecord(insert, i)}
                      disabled={readonly}
                      className="flex flex-col md:flex-row items-start md:items-center w-1/3 mb-2"
                      labelClassName="text-sm font-semibold w-24"
                      wrapperInputContainerClassName="w-full"
                      tooltipText={getCreatorInfo(priceFinding)}
                      required
                      onChange={(e) => {
                        const newValue = e.value
                        form.setFieldValue(
                          `priceFindings.[${i}].price`,
                          newValue,
                        )
                        ensureEmptyPriceFindingRecord(insert, i, {
                          price: newValue,
                        })
                      }}
                    />
                  </div>
                )
              })}
            </>
          )
        }}
      />

      <div className="flex justify-end">
        <PriceCalcContainer className="w-full lg:w-auto">
          {!counterofferPayoutAmount && (
            <div className="flex flex-col">
              <div className="flex justify-between items-center mb-3.5">
                <TitleStyled>{t('price_calculation')}</TitleStyled>
                <Switch
                  checked={overwriteItemPayouts}
                  label={t('override_value')}
                  className="flex flex-row items-start md:items-center mb-2"
                  labelClassName="text-sm font-semibold mr-4"
                  onChange={(e) => {
                    setFieldValue(
                      'overwrittenItemValue',
                      itemPawnValuesEntry?.adjustedMarketValue,
                    )
                    setOverwriteItemPayouts(Boolean(e.value))
                  }}
                  disabled={overWriteItemPayoutsReadonly}
                />
              </div>

              {!overwriteItemPayouts && !overWriteItemPayoutsReadonly ? (
                <div>
                  <InputNum
                    label={t('item_value')}
                    className="flex flex-col md:flex-row items-start md:items-center mb-2"
                    labelClassName="text-sm font-semibold w-12.5"
                    inputContainerClassName="w-52"
                    value={
                      itemPawnValuesEntry &&
                      itemPawnValuesEntry?.adjustedMarketValue
                    }
                    onChange={(e) => {
                      setFieldValue(
                        'overwrittenItemValue',
                        e.value && e.value > 0 ? e.value : undefined,
                      )
                    }}
                    disabled
                    loading={calculationRunning}
                  />
                </div>
              ) : (
                <>
                  <div className="p-input-icon-right mb-2">
                    <InputNum
                      label={t('manual_value_estimation')}
                      className="flex flex-col md:flex-row items-start md:items-center"
                      labelClassName="text-sm font-semibold w-12.5"
                      inputContainerClassName="w-52"
                      value={
                        overwrittenPurchasePayoutAmount &&
                        itemPurchaseValuesEntry
                          ? itemPurchaseValuesEntry.adjustedMarketValue
                          : itemPawnValuesEntry
                            ? itemPawnValuesEntry.adjustedMarketValue
                            : undefined
                      }
                      disabled
                    />
                  </div>
                </>
              )}

              <InputNum
                label={t('payout_pawn')}
                className="flex flex-col md:flex-row items-start md:items-center mb-2"
                labelClassName="text-sm font-semibold w-12.5"
                inputContainerClassName="w-52"
                value={
                  overwriteItemPayouts && overwrittenPawnPayoutAmount
                    ? overwrittenPawnPayoutAmount
                    : itemPawnValuesEntry?.payoutAmount
                }
                onChange={(e) => {
                  const value = e.value
                  setValues((prevValues) => ({
                    ...prevValues,
                    overwrittenPawnPayoutAmount:
                      value && value > 0 ? value : undefined,
                    overwrittenPurchasePayoutAmount: undefined,
                  }))
                }}
                disabled={!overwriteItemPayouts || readonly}
                errorText={
                  (errors?.counterofferPayoutAmount &&
                    t(errors?.counterofferPayoutAmount)) ||
                  (overwrittenItemPayoutsError &&
                    t(overwrittenItemPayoutsError))
                }
                isInvalid={
                  Boolean(errors?.counterofferPayoutAmount) ||
                  Boolean(overwrittenItemPayoutsError)
                }
                loading={calculationRunning}
              />

              <InputNum
                label={t('payout_purchase')}
                className="flex flex-col md:flex-row items-start md:items-center mb-2"
                labelClassName="text-sm font-semibold w-12.5"
                inputContainerClassName="w-52"
                value={
                  overwriteItemPayouts && overwrittenPurchasePayoutAmount
                    ? overwrittenPurchasePayoutAmount
                    : itemPurchaseValuesEntry?.payoutAmount
                }
                onChange={(e) => {
                  const value = e.value
                  setValues((prevValues) => ({
                    ...prevValues,
                    overwrittenPurchasePayoutAmount:
                      value && value > 0 ? value : undefined,
                    overwrittenPawnPayoutAmount: undefined,
                  }))
                }}
                disabled={(!overwriteItemPayouts && !isVehicle) || readonly}
                errorText={
                  (errors?.counterofferPayoutAmount &&
                    t(errors?.counterofferPayoutAmount)) ||
                  (overwrittenItemPayoutsError &&
                    t(overwrittenItemPayoutsError))
                }
                isInvalid={
                  Boolean(errors?.counterofferPayoutAmount) ||
                  Boolean(overwrittenItemPayoutsError)
                }
                loading={calculationRunning}
              />
              {!overWriteItemPayoutsReadonly && overwriteItemPayouts && (
                <Button
                  onClick={() => {
                    setOverwriteItemPayouts(false)
                  }}
                  text
                  severity="danger"
                  label="Reset"
                  size="small"
                />
              )}
            </div>
          )}

          {Boolean(counterofferPayoutAmount && counterofferPayoutAmount) && (
            <Input
              label={t('final_offer_price', { dealType: t(dealType) })}
              className="flex flex-col md:flex-row items-start md:items-center mb-2"
              labelClassName="text-sm font-semibold w-12.5"
              inputContainerClassName="w-52"
              disabled
              value={`${counterofferPayoutAmount}`}
            />
          )}
        </PriceCalcContainer>
      </div>
    </div>
  )
}

const getCreatorInfo = (priceFinding: PriceFinding | PriceFindingArgs) => {
  if ('createdAt' in priceFinding && 'createdBy' in priceFinding) {
    return `${priceFinding.createdBy?.firstname} ${priceFinding.createdBy?.lastname} - ${dayjs(priceFinding.createdAt).format('DD.MM.YYYY HH:mm')}`
  }

  return undefined
}

const TitleStyled = styled.h5`
  color: #000;
  font-family: Inter;
  font-size: 1.09375rem;
  font-style: normal;
  font-weight: 600;
  line-height: 1.09375rem;
  margin-bottom: 0.66rem;
`

const PriceCalcContainer = styled.div`
  padding: 1.3125rem;
  gap: 0.875rem;
  border-radius: 0.3125rem;
  background: var(--surface-50, #fafafa);
`
export default PriceDetermination
