import { Dropdown } from 'primereact/dropdown'
import { ECustomDealStatusType } from '@/schemaTypes'

interface Props {
  status?: ECustomDealStatusType | null
  onEventChange: (status: ECustomDealStatusType) => void
  disabled?: boolean
}

const getAllowedDealStates = (lastEvent: ECustomDealStatusType) => {
  switch (lastEvent) {
    case ECustomDealStatusType.Created:
      return [
        ECustomDealStatusType.InProgress,
        ECustomDealStatusType.Reviewing,
        ECustomDealStatusType.Counteroffer,
        ECustomDealStatusType.Accepted,
        ECustomDealStatusType.Declined,
        ECustomDealStatusType.Canceled,
      ]
    case ECustomDealStatusType.InProgress:
      return [
        ECustomDealStatusType.Reviewing,
        ECustomDealStatusType.Counteroffer,
        ECustomDealStatusType.Accepted,
        ECustomDealStatusType.Declined,
        ECustomDealStatusType.Canceled,
      ]
    case ECustomDealStatusType.Reviewing:
      return [
        ECustomDealStatusType.Counteroffer,
        ECustomDealStatusType.Accepted,
        ECustomDealStatusType.Declined,
        ECustomDealStatusType.Canceled,
      ]
    case ECustomDealStatusType.Counteroffer:
      return [ECustomDealStatusType.Declined, ECustomDealStatusType.Canceled]
    case ECustomDealStatusType.Canceled:
    default:
      return []
  }
}

export function CustomDealStatus(props: Props) {
  const { status, onEventChange, disabled } = props

  if (!status) return null

  return (
    <Dropdown
      className="w-64"
      value={status}
      onChange={(e) => {
        const newStatus = e.target.value as ECustomDealStatusType

        if (newStatus !== status) {
          onEventChange(newStatus)
        }
      }}
      disabled={disabled}
      options={[status, ...getAllowedDealStates(status)].map((status) => ({
        value: status,
        label: status,
      }))}
    />
  )
}
