import { FunctionComponent } from 'react'
import { ItemDraftQuestionsFormData } from '@/domains/customDeals/components/CustomDealDetail/CustomDealItemSelect'
import { EItemEvaluationActionType } from '@/domains/deals/components/constants/enums'
import AddItemToCustomDeal from '@/domains/items/components/Item/ItemEvaluation/AddItemToCustomDeal'
import AddOrChangeItem from '@/domains/items/components/Item/ItemEvaluation/AddOrChangeItem'
import ChangeItemQuestionsAnswers from '@/domains/items/components/Item/ItemEvaluation/ChangeItemQuestionsAnswers'
import EditVehicleProperties from '@/domains/items/components/Item/ItemEvaluation/EditVehicleProperties'
import { Deal, DealItem, EBusinessUnit, Item, Scalars } from '@/schemaTypes'

type ItemEvaluationProps = {
  actionType: EItemEvaluationActionType
  deal?: Deal | undefined | null
  productObjectID?: string
  productVariantId?: string
  item?: DealItem | Item | undefined | null
  durationInDays?: number
  minimumPawnDuration?: number
  companyId?: Scalars['ObjectId']
  onSaveItemDraft?: (formData: ItemDraftQuestionsFormData) => void
}

const ItemEvaluation: FunctionComponent<ItemEvaluationProps> = (props) => {
  const {
    deal,
    actionType,
    productObjectID,
    productVariantId,
    item,
    onSaveItemDraft,
    durationInDays,
    minimumPawnDuration,
    companyId,
  } = props

  if (
    actionType === EItemEvaluationActionType.ADD_PRODUCT_TO_DEAL &&
    deal &&
    (productObjectID || productVariantId)
  )
    return (
      <AddOrChangeItem
        deal={deal}
        productObjectID={productObjectID}
        productVariantId={productVariantId}
      />
    )
  else if (
    actionType === EItemEvaluationActionType.EXCHANGE_ITEM &&
    deal &&
    item &&
    (productObjectID || productVariantId) &&
    deal.businessUnit === EBusinessUnit.General
  )
    return (
      <AddOrChangeItem
        deal={deal}
        item={item as DealItem}
        productObjectID={productObjectID}
        productVariantId={productVariantId}
      />
    )
  else if (
    actionType === EItemEvaluationActionType.EXCHANGE_ITEM &&
    deal &&
    item &&
    deal.businessUnit === EBusinessUnit.Car
  )
    return <EditVehicleProperties deal={deal} item={item as Item} />
  else if (
    actionType === EItemEvaluationActionType.CHANGE_ITEM_ANSWER &&
    deal &&
    item
  )
    return <ChangeItemQuestionsAnswers deal={deal} item={item as Item} />
  else if (
    actionType === EItemEvaluationActionType.ADD_PRODUCT_TO_CUSTOME_DEAL &&
    (productObjectID || productVariantId) &&
    durationInDays != null &&
    minimumPawnDuration
  )
    return (
      <AddItemToCustomDeal
        productObjectID={productObjectID}
        productVariantId={productVariantId}
        onSaveItemDraft={onSaveItemDraft}
        companyId={companyId}
        durationInDays={durationInDays}
        minimumPawnDuration={minimumPawnDuration}
      />
    )

  return null
}

export default ItemEvaluation
