import React from 'react'
import { useTranslation } from 'react-i18next'
import ReactJson from 'react-json-view'
import { UIError } from '@/types'

export interface ErrorsDisplayerProps {
  errors: UIError[]
}

const ErrorsDisplayer = (props: ErrorsDisplayerProps) => {
  const { errors } = props
  const { t } = useTranslation()

  return (
    <p className="o-type-alert">
      {errors.map((error) => {
        const descriptions: string[] = []

        if (typeof error.description === 'string') {
          descriptions.push(error.description)
        } else {
          descriptions.push(error.description.message)
        }

        return (
          <div className="u-mb-15">
            <div className="o-type-title">{error.title}</div>
            {(descriptions || []).map((description) => (
              <div className="o-type-alert u-mt-5">{description}</div>
            ))}
            {error.data != null && Object.keys(error.data).length > 0 && (
              <div className="o-type-12 u-mt-15">
                <ReactJson
                  name={t('error.error_details')}
                  displayDataTypes={false}
                  displayObjectSize={false}
                  src={error.data}
                  enableClipboard={false}
                />
              </div>
            )}
          </div>
        )
      })}
    </p>
  )
}

export default ErrorsDisplayer
