import { Column } from 'primereact/column'
import { DataTable } from 'primereact/datatable'
import { Panel } from 'primereact/panel'
import { FunctionComponent, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { EFeeType, WithdrawnDeal } from '@/schemaTypes'
import {
  getLatestEventForStatus,
  getTotalGrossFeeAmountOfType,
} from '@/utils/deal'
import { displayLocalAmount, paymentTypeUnion } from '@/utils/misc'

interface WithdrawnDealsProps {
  withdrawnDeals: WithdrawnDeal[]
}

export const WithdrawnDeals: FunctionComponent<WithdrawnDealsProps> = (
  props,
) => {
  const { withdrawnDeals } = props
  const { t } = useTranslation()

  const computedWithdrawnDeals = useMemo(
    () =>
      withdrawnDeals?.map((withdrawnDeal) => {
        const paybackEvent = getLatestEventForStatus(
          withdrawnDeal.deal,
          'DealPaybackConfirmedEvent',
        )
        const extensionEvent = getLatestEventForStatus(
          withdrawnDeal.deal,
          'DealExtensionConfirmedEvent',
        )

        if (!paybackEvent && !extensionEvent) {
          return {
            ...withdrawnDeal,
            paymentType: t(
              paymentTypeUnion(withdrawnDeal.paymentType).toLowerCase(),
            ),
            payoutAmount: '-',
            totalFees: '-',
            withdrawalFee: '-',
          }
        }

        const totalFees = displayLocalAmount(
          getTotalGrossFeeAmountOfType(
            (paybackEvent ?? extensionEvent).dealCalculation
              .appliedUsedFeeDefinitions,
          ),
        )
        const withdrawalFee = displayLocalAmount(
          getTotalGrossFeeAmountOfType(
            (paybackEvent ?? extensionEvent).dealCalculation
              .appliedUsedFeeDefinitions,
            EFeeType.Withdrawal,
          ),
        )
        return {
          ...withdrawnDeal,
          paymentType: t(
            paymentTypeUnion(withdrawnDeal.paymentType).toLowerCase(),
          ),
          payoutAmount: displayLocalAmount(
            withdrawnDeal.deal.dealFinalValues.payoutAmount,
          ),
          totalFees,
          withdrawalFee,
        }
      }),
    [withdrawnDeals, t],
  )

  return (
    <Panel
      header={t('auction.withdrawn_deals')}
      className="mb-6 w-full mt-4"
      toggleable
    >
      <DataTable value={computedWithdrawnDeals} size="small">
        <Column field="deal.bookingNumber" header={t('deal.booking_number')} />
        <Column field="shop.name" header={t('shop.label')} />
        <Column field="withdrawalReason" header={t('reason')} />
        <Column field="paymentType" header={t('payment_type')} />
        <Column field="payoutAmount" header={t('payout.payout_amount')} />
        <Column field="totalFees" header={t('total_fees')} />
        <Column
          field="withdrawalFee"
          header={t('fee_type.fee_type_withdrawal')}
        />
      </DataTable>
    </Panel>
  )
}
