import {
  AutoComplete,
  AutoCompleteCompleteEvent,
} from 'primereact/autocomplete'
import { useState } from 'react'

interface Props {
  options: { value: string; label: string }[]
  onChangeField: (fieldName: string, fieldValue: string | null) => void
  fieldName: string
  initialValue?: string
}

export function CustomDealEmployeeAutocomplete({
  options,
  onChangeField,
  fieldName,
  initialValue,
}: Props) {
  const [filteredOptions, setFilteredOptions] = useState(options)
  const [value, setValue] = useState(initialValue)

  const searchEmployee = (event: AutoCompleteCompleteEvent) => {
    if (!event.query.trim().length) {
      setFilteredOptions(options)
    } else {
      setFilteredOptions(
        options.filter((option) =>
          option.label.toLowerCase().includes(event.query.toLowerCase()),
        ),
      )
    }
  }

  return (
    <AutoComplete
      className="w-64"
      value={value}
      onChange={(event) => {
        setValue(event.value)
        if (typeof event.value === 'object' && event.value?.value) {
          onChangeField(fieldName, event.value.value)
        }
      }}
      onClear={() => {
        onChangeField(fieldName, null)
      }}
      suggestions={filteredOptions}
      completeMethod={searchEmployee}
      forceSelection
      field="label"
      dropdown
    />
  )
}
